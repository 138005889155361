<template>
  <div class="mobile-input">
    <label
      class="mobile-input-wrap"
      :class="{'required':required, 'required-blue': !required && requiredBlue}"
    >
      <div
        class="mobile-input-wrap-title"
      >
        {{ label | translate }}
        <button
          v-if="!!description"
          class="mobile-input-wrap-title-btn"
          @click="showHideDescription"
        >
          <img src="@/assets/images/icon_description.svg">
        </button>
      </div>
      <div
        class="form-textarea__fieldset"
        :class="{ 'read-only': readOnly }"
      >
        <textarea
          ref="textarea"
          v-model="computedInternalValue"
          v-bind="{...castAttr}"
          class="form-textarea"
          :class="{ 'read-only': readOnly }"
          style="
            height: auto;
            resize: none;
            overflow-y: auto;
          "
          :rows="rows"
        />
      </div>
    </label>
    <div
      v-if="descriptionOn"
      class="mobile-input-description"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
import MobileBaseInput from "./MobileBaseInput";

export default {
  name: "MobileTextarea",
  extends: MobileBaseInput,
  mixins: [],
  props:{
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: 999999999,
    },
    rows: {
      type: Number,
      default: 10,
    }
  },
  computed: {
    castAttr(){
      return this.$attrs
    },

    computedInternalValue: {
      get() {
        return this.internalValue
      },

      set(newValue) {
        this.internalValue = newValue
      },
    },
  },
    watch: {
      internalValue() {
        if (!this.internalValue || !this.$refs.textarea) return
        this.$refs.textarea.value = this.internalValue;
        // this.autoGrow(this.$refs.textarea);
      }
    },
    mounted() {
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.mobile-input{
  font-size: 0.8rem;
  padding: 0.325rem 0.5rem;
  border-bottom: 1px solid #dddddd;
  &-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title{
      display: flex;
      align-items: center;
      padding: 0.25rem;
      font-weight: normal;
      text-align: left;
      flex: 2;
      &-btn{
        color: #0c98fe;
        margin-left: 4px;
      }
        & img {
          display: flex;
          align-items: center;
        }
    }
    &-input{
      display: flex;
      align-items: center;
      flex: 3;
      &-sublabel{
        overflow: auto;
        color: #6e6e6e;
        margin-right: 6px;
        &:after{
          content: ':';
        }
      }
      input{
        padding: 0.25rem 1rem 0.25rem 0.25rem;
        border: 1px solid #dddddd;
        text-align: right;
        width: 100%;
        flex: 1;
        &:focus{
          outline: none;
        }
        &:disabled{
          background: #dddddd;
        }
      }
      &-unit{
        overflow: auto;
        color: #6e6e6e;
        margin-left: 6px;
      }
    }
    &.required:before{
      content: '*';
      color: #ff0000;
      position: absolute;
      left: -3px;
    }
    &.required-blue:before{
      content: '*';
      color: #0c98fe;
      position: absolute;
      left: -3px;
    }
  }
  &-description{
    font-size: 0.810rem;
    line-height: 1.2rem;
    color: #0c98fe;
    text-align: left;
    padding: 0.25rem 0.25rem;
    /*&:before{
      content:url(/img/icon_description.svg);
    }*/
  }
  .form-textarea__fieldset {
    background: #fff;
    border: 1px solid #ddd;
    text-align: left;
    flex: 3;
    padding: 6px 0;

    &.read-only {
      border: none;
    }
  }

  /* TEXTAREA */
  textarea.form-textarea {
    text-align: inherit;
    width: 100%;
    overflow: hidden;
    display: block;
    margin: 0 0;
    /*padding: 8px 4px;*/
    padding: 0;
    font-size: 0.8rem;
    border: 0 !important;
    height: 24px;
    padding: 6px;

    &.read-only {
      border: none;
    }
  }
}
</style>